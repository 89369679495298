import React, { PureComponent } from "react";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import Error from "./Error";

class Capacity extends PureComponent {
  handleChange = (e) => {
    // reset related fields
    this.props.setFieldValue("education", "");
    this.props.setFieldValue("level", "");
    this.props.setTouched(
      Object.assign(this.props.touched, {
        education: false,
        level: false,
        capacity: true,
      })
    );
    return this.props.handleChange(e);
  };
  render() {
    const { field, values, errors, touched, handleBlur, disabled } = this.props;
    return (
      <FormGroup>
        <Label htmlFor={field}>身份</Label>
        {["學生", "家長", "教師", "其他"].map((capacity, idx) => {
          return (
            <FormGroup check key={idx}>
              <Label check>
                <Input
                  type="radio"
                  name={field}
                  value={capacity}
                  // eslint-disable-next-line
                  checked={values.capacity === capacity}
                  onChange={this.handleChange}
                  onBlur={handleBlur}
                  disabled={disabled}
                />
                {capacity}
              </Label>
            </FormGroup>
          );
        })}
        <FormText className="disclaimer">
          投票者如屬<b>香港和澳門地區</b>學校師生和家長
          ，在選擇「身分」後，將顯示並可選擇的「所屬學校」
        </FormText>
        <Error field={field} errors={errors} touched={touched} />
      </FormGroup>
    );
  }
}

export default Capacity;
