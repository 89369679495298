import React from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import DebugNav from "./components/DebugNav";
import Footer from "./components/Footer";
import Header from "./components/Header";
import constants from "./constant";

import CodeVoting from "./pages/CodeVoting";
import Home from "./pages/Home";
import Voting from "./pages/Voting";

const App = () => {
  const has_voting_page = !constants.POST_VOTING;
  return (
    <Container className="App">
      <Header />
      <Router>
        <>
          <DebugNav />
          <Switch>
            <Route exact path="/" component={Home} />
            {has_voting_page && <Route path="/voting" component={Voting} />}
            {has_voting_page && (
              <Route exact path="/g" component={CodeVoting} />
            )}
            <Route path="*" component={Home} />
          </Switch>
        </>
      </Router>
      <Footer />
    </Container>
  );
};

export default App;
