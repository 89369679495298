import React, { PureComponent } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Row, Col } from "reactstrap";
import { Alert, Button, Form, FormGroup, Label, Input } from "reactstrap";

import Constant from "../constant";
import NetworkService from "../NetworkService";
import PreVotingBanner from "../components/PreVotingBanner";
import printError from "../errors";

import AgeGroup from "../form/AgeGroup";
import Choice from "../form/Choice";
import Code from "../form/Code";
import DisplayFormikState from "../form/DisplayFormikState";
import Education from "../form/Education";
import Gender from "../form/Gender";
import How from "../form/How";
import Level from "../form/Level";
import Reason from "../form/Reason";
import SchoolFromCode from "../form/SchoolFromCode";

const initialValues = {
  code: "",
  gender: "",
  ageGroup: "",
  capacity: "學生",
  education: "",
  level: "",
  choice: "",
  reason: "",
  how: [],
  howtext: "",
};
const validationSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .matches(Constant.CODE_REGEX, {
      message: "無效的短代碼",
      excludeEmptyString: true,
    })
    .required("請輸入短代碼"),
  gender: Yup.string().required("請選擇性別"),
  ageGroup: Yup.number().integer().required("請選擇年齡組別"),
  capacity: Yup.string().required("請選擇投票人身份"),
  education: Yup.object().required("請輸入教育程度"),
  level: Yup.object().required("請輸入年級"),
  choice: Yup.number().integer().required("請選擇年度歷史人物"),
  reason: Yup.string().trim(),
  how: Yup.array(),
  howtext: Yup.string().trim(),
});

class MyForm extends PureComponent {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleSubmit,
      handleReset,
      isSubmitting,
      submitError,
      submitted,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Code field="code" {...this.props} />
        <Gender field="gender" {...this.props} />
        <AgeGroup field="ageGroup" {...this.props} />
        {/* capacity is fixed */}
        <FormGroup>
          <Label htmlFor="capacity">身份</Label>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="capacity"
                value={values.capacity}
                checked={true}
                disabled={true}
              />
              {values.capacity}
            </Label>
          </FormGroup>
        </FormGroup>
        {/* resolve school from code */}
        <SchoolFromCode
          code={values.code}
          error={errors.code}
          touched={touched.code}
        />
        <Education field="education" {...this.props} code={true} />
        {values.education.label === "中學" ||
        values.education.label === "小學" ? (
          <Level
            field="level"
            {...this.props}
            primary={values.education.label === "小學"}
          />
        ) : null}
        <Choice field="choice" {...this.props} />
        <Reason field="reason" {...this.props} />
        <How field="how" field1="howtext" {...this.props} />
        <FormGroup>
          {submitted ? (
            <Alert color="success">感謝你的投票！</Alert>
          ) : (
            <>
              <Button
                type="button"
                outline
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
                style={{ marginRight: "0.5em" }}
              >
                重設
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || Constant.PREVIEW_VOTING}
              >
                提交
              </Button>
              {Object.keys(errors).length > 0 && (
                <div className="FormError">{"表格有誤，請檢視並重新輸入"}</div>
              )}
              {submitError && <div className="FormError">{submitError}</div>}
            </>
          )}
        </FormGroup>
        <DisplayFormikState {...this.props} />
      </Form>
    );
  }
}

class CodeVoting extends PureComponent {
  state = {
    submitError: undefined,
    submitted: false,
  };

  handleSubmit = async (values, { setSubmitting, setStatus }) => {
    const payload = {
      ...values,
      // pick `label` field for select inputs
      education: values.education.label,
      level: values.level.label,
      type: "code",
    };
    if (payload.howtext) {
      payload.how = [...payload.how];
      payload.how.push(payload.howtext);
    }
    delete payload.howtext;

    const resp = await NetworkService.castVote(payload);

    if (!resp.ok) {
      const body = await resp.json();
      console.error(body);
      this.setState({
        submitError: printError(body),
      });

      setSubmitting(false);
    } else {
      this.setState({
        submitted: true,
      });
    }
  };

  render() {
    return (
      <>
        {Constant.PREVIEW_VOTING && <PreVotingBanner />}
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              children={(props) => <MyForm {...props} {...this.state} />}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default CodeVoting;
