import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Error from "./Error";

const HowOptions = [
  "郵遞至學校的資料包",
  "國史教育中心（香港）網站",
  "知史網站/Facebook/Instagram等社交媒體平台",
  "教師 / 同事 / 親戚 / 朋友等他人轉告",
];

const How = ({
  field,
  field1,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <FormGroup>
      <Label htmlFor={field}>請問您從什麼途徑得知今次活動？（可選多項）</Label>
      <br />
      {HowOptions.map((option, idx) => {
        const checked = values.how.includes(option);
        return (
          <FormGroup check key={idx}>
            <Label check>
              <Input
                type="checkbox"
                name={field}
                checked={checked}
                onChange={handleChange}
                onBlur={handleBlur}
                value={option}
              />
              {option}
            </Label>
          </FormGroup>
        );
      })}
      <FormGroup check>
        <Label check>
          <Input
            type="text"
            name={field1}
            value={values[field1]}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="其他（選填）"
          />
        </Label>
      </FormGroup>
      <Error field={field} errors={errors} touched={touched} />
      <br />{" "}
    </FormGroup>
  );
};

export default How;
