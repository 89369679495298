const constants = {
  MOBILE_REGEX:
    /^((\+852)?[4-9]\d{7}|\+861\d{10}|\+8536\d{7}|\+8869[0-8]\d{7}|\+65[89]\d{7}|\+447\d{9}|\+614\d{8}|\+1\d{10}|\+601\d{8,9})$/,
  OTP_REGEX: /^[0-9]{6,}$/,
  TOKEN_REGEX: /^[A-Z0-9]{20}$/,
  CODE_REGEX: /^[A-Z0-9]{6}$/,
  // PREVIEW_VOTING: true,
  // POST_VOTING: true,
};

export default constants;
